import moment from "moment";
import { Breakdowns } from "../interface/breakdowns";
import { Checklist } from "../interface/checklist";
import { Client } from "../interface/client";
import { Comment } from "../interface/comment";
import { CustomState } from "../interface/custom-state.model";
import { Note } from "../interface/note";
import { Operation } from "../interface/operation";
import { Pdf } from "../interface/pdf";
import { ApiFinance } from "../interface/ro-finance";
import { RoFull } from "../interface/ro-full";
import { RoLaborFull } from "../interface/ro-labor-full";
import { RoLaborTemp } from "../interface/ro-labor-temp";
import { RoPart } from "../interface/ro-part";
import { RoPartFull } from "../interface/ro-part-full";
import { DESCRIPTIVE_ITEM_STATUS, RoPartTemp } from "../interface/ro-part-temp";
import { Tag } from "../interface/tag";
import { ThirdPartyService } from "../interface/third-party-service";
import { Vehicle } from "../interface/vehicle";
import { authorizationTypes } from "../shared/lists/authorization-types";
import { laborStates } from "../shared/lists/labor-states";
import { partStates } from "../shared/lists/part-states";
import { RO_STATES } from "../shared/lists/ro-states";
import { RoStateGroup } from "../shared/pipes/ro-state-group.pipe";
import { ObjectId } from "../shared/type-aliases/object-id";
import { Utilities } from "./utilities";

export class MkgOS implements RoFull {
    id?: string;
    type?: string;
    code?: number;
    codeSystem?: number;
    problem?: string;
    authorizationType?: number;
    client?: string;
    vehicle?: string;
    appointment?: string;
    deliveryForecast?: string;
    breakdowns?: Breakdowns;
    complementaryApprove?: number;
    createdAt?: string;
    priority?: number;
    showPDF?: number;
    shortUrl?: string;
    startDate?: string;
    receiveDate?: string;
    evaluationDate?: string;
    evaluation?: number;

    /** @unused */
    receipts?: string;

    /** used into WebClient app */
    companyLogo?: string;
    observation?: string;
    km?: number;
    recall?: string;
    authDisposal?: number;

    /** id of checklist-model-item applied to this OS */
    checklistModelItem?: string;

    /** id of survey applied to this OS */
    surveyModelItem?: string

    /**
     * A string cointaining the company cnpj, the company name and company site, joined by two asterisks (**)
    *
    * Used in WebCliente
    * @example"companycnpj**companyname**companysite" */
    companyInfo?: string;

    /** Email of the user that opened the buget (Consultor)*/
    user?: string;
    updatedAt?: string;
    lastUpdate?: string;
    evaluationText?: string;
    pdf?: Pdf;
    operation?: Operation;
    additionalState?: string;

    /** @test */
    prisma?: string;
    /** @todo */
    model?: string;
    /** @todo */
    serie?: string;

    packet?: string;
    review?: string;
    menu?: string;
    checklist?: Checklist[] = [];
    parts?: RoPartFull[] = [];
    labors?: RoLaborFull[] = [];
    chat?: Comment[] = [];
    tags?: Tag[] = [];
    notes?: Note[] = [];
    partsTMP?: RoPartTemp[] = [];
    laborsTMP?: RoLaborTemp[] = [];
    thirdPartyServices?: ThirdPartyService[] = [];
    paymentConditions?: ApiFinance[] = [];

    /** When use defalt client, show this value as client name */
    clientTmp?: string;

    /** When use defalt vehicle, show this value as vehicle plate */
    vehicleTmp?: string;

    // Discount Control
    osBlock?: number;

    // the id of selected farm when document type of client is rural producer
    idFarmer?: ObjectId;

    private _valueDiscountLabors = 0;
    private _valueDiscountOs = 0;
    private _valueDiscountParts = 0;
    /** percentage of discount given */
    private _generalDiscount = 0;
    private _discountParts = 0;
    private _discountLabors = 0;

    // absent in the API
    additionalStateObject?: CustomState;
    operationId?: string;
    clientObject?: Client;
    vehicleObject?: Vehicle;
    // partsGrossValue?: number;
    // partsNetValue?: number;
    // laborsGrossValue?: number;
    // laborsNetValue?: number;
    budgetCode?: number;
    partsExpenseValue?: number;
    partsTMPGrossValue?: number;
    laborsTMPGrossValue?: number;

    /** The OS liquid value into database, multiplied by 100 */
    apiLiquidValue?: number;

    private _stateGroup: RoStateGroup;
    get stateGroup() {
        return this._stateGroup;
    }

    private _currentState: number;
    set currentState(value: number) {
        switch (value) {
            case RO_STATES.budget: // 2
            case RO_STATES.authorization: // 4
            case RO_STATES.appointment: // 5
            case RO_STATES.readyToStart: // 6
                this._stateGroup = 'budget';
                break;
            case RO_STATES.started: // 7
                this._stateGroup = 'opened';
                break;
            case RO_STATES.toReceive: // 8
            case RO_STATES.evaluation: // 9
                this._stateGroup = 'closed';
                break;
            case RO_STATES.finished: // 10
                this._stateGroup = 'ended';
                break;
            case RO_STATES.rejected: // 11
                this._stateGroup = 'rejected';
                break;
            default:
                this._stateGroup = "ended";
                break;
        }
        this._currentState = value;

    }
    get currentState() {
        return this._currentState;
    }

    constructor(apiOS: any) {
        if (Number.isFinite(apiOS.liquidValue)) {
            if (moment(apiOS.lastUpdate).isSameOrBefore(moment('2024-09-01'))) {
                this.apiLiquidValue = Number((apiOS.liquidValue / 100).toFixed(2));
            }
        }

        delete apiOS.liquidValue; // prevent to assign a readonly property

        // dont user setters on constructor
        this._valueDiscountLabors = apiOS.valueDiscountLabors;
        this._valueDiscountOs = apiOS.valueDiscountOs;
        this._valueDiscountParts = apiOS.valueDiscountParts;
        this._generalDiscount = apiOS.generalDiscount;
        this._discountParts = apiOS.discountParts;
        this._discountLabors = apiOS.discountLabors;
        delete apiOS.valueDiscountLabors;
        delete apiOS.valueDiscountOs;
        delete apiOS.valueDiscountParts;
        delete apiOS.generalDiscount;
        delete apiOS.discountParts;
        delete apiOS.discountLabors;

        Object.assign(this, apiOS);

        if (apiOS.authorizationType == authorizationTypes.rejected.id) {
            this.currentState = RO_STATES.rejected;
        }

        this.operationId = apiOS.operation as any;

        this._initializeArrays();


        for (const roPart of this.parts) {
            roPart.saleValue = (roPart.saleValue || 0) / 100;
            roPart.liquidValue = (roPart.liquidValue || 0) / 100;
            roPart.discountValue = (roPart.discountValue || 0) / 100;
            roPart.purchaseValue = (roPart.purchaseValue || 0) / 100;
            roPart.purchaseAverage = (roPart.purchaseAverage || 0) / 100;
            roPart.discountProp = (roPart.discountProp || 0) / 100;

            /** 
             * Está sendo invertido o campo de observações para que as observações
             * que ja existiam (campo 'observation') aparecam como padrão no pdf  
            */
            const observation = roPart.observation ?? "";
            roPart.observation = roPart.internalObservation ?? "";
            roPart.internalObservation = observation;

            // this.recalculatePart(roPart);
        }

        // sum laborTMP values 
        for (const partTMP of this.partsTMP) {
            let netValue = partTMP.amount * partTMP.saleValue; // gross value
            netValue -= Utilities.calcPercentage(this.discountParts, netValue, 'round'); // discount of parts
            netValue -= Utilities.calcPercentage(this.generalDiscount, netValue);// discount general of OS
            Object.defineProperty(partTMP, "_netValue", { value: netValue, enumerable: false, writable: false })
            const internalObservation = partTMP.internalObservation;
            const observation = partTMP.observation;
            partTMP.observation = internalObservation;
            partTMP.internalObservation = observation;
        }

        for (const roLabor of this.labors) {
            roLabor.saleValue = (roLabor.saleValue || 0) / 100;
            roLabor.discountValue = (roLabor.discountValue || 0) / 100;
            roLabor.liquidValue = (roLabor.liquidValue || 0) / 100;
            roLabor.discountProp = (roLabor.discountProp || 0) / 100;

            /** 
             * Está sendo invertido o campo de observações para que as observações
             * que ja existiam (campo 'observation') aparecam como padrão no pdf  
            */
            const observation = roLabor.observation ?? "";
            roLabor.observation = roLabor.internalObservation ?? "";
            roLabor.internalObservation = observation;

            // this.recalculateLabor(roLabor);
        }


        // sum laborTMP values 
        for (const laborTMP of this.laborsTMP) {
            let netValue = laborTMP.amount * laborTMP.saleValue; // gross value
            netValue -= Utilities.calcPercentage(this.discountLabors, netValue, 'round'); // discount of labors
            netValue -= Utilities.calcPercentage(this.generalDiscount, netValue);// discount general of OS
            Object.defineProperty(laborTMP, "_netValue", { value: netValue, enumerable: false, writable: false })
            const internalObservation = laborTMP.internalObservation;
            const observation = laborTMP.observation;
            laborTMP.observation = internalObservation;
            laborTMP.internalObservation = observation;
        }

        for (const outsourced of this.thirdPartyServices) {
            outsourced.id = outsourced._id;
            outsourced.collectedValue = (outsourced.collectedValue || 0) / 100;
            outsourced.paymentValue = (outsourced.paymentValue || 0) / 100;
        }

        // treat discounts for older OSs
        if (this.discountParts && !this.valueDiscountParts) {
            this.valueDiscountParts = Utilities.calcPercentage(this.discountParts, this.baseOfPartsDiscount)
        }

        if (this.discountLabors && !this.valueDiscountLabors) {
            this.valueDiscountLabors = Utilities.calcPercentage(this.discountLabors, this.baseOfLaborsDiscount);
        }

        if (this.generalDiscount && !this.valueDiscountOs) {
            this.valueDiscountOs = Utilities.calcPercentage(this.generalDiscount, this.baseOfGeneralDiscount);
        }

    }

    /** Prevent error: reduce of empty array */
    private _initializeArrays() {
        if (!Array.isArray(this.parts))
            this.parts = [];
        if (!Array.isArray(this.partsTMP))
            this.partsTMP = [];
        if (!Array.isArray(this.labors))
            this.labors = [];
        if (!Array.isArray(this.laborsTMP))
            this.laborsTMP = [];
        if (!Array.isArray(this.thirdPartyServices))
            this.thirdPartyServices = [];
        if (!Array.isArray(this.paymentConditions))
            this.paymentConditions = [];
        if (!Array.isArray(this.chat))
            this.chat = [];
        if (!Array.isArray(this.tags))
            this.tags = [];
        if (!Array.isArray(this.checklist))
            this.checklist = [];
        if (!Array.isArray(this.notes))
            this.notes = [];
    }

    set valueDiscountParts(value: number) {
        this._valueDiscountParts = Number(value.toFixed(2));
        this._discountParts = Utilities.getPercentage(this._valueDiscountParts, this.baseOfPartsDiscount, 10);
    }

    get valueDiscountParts() {
        return this._valueDiscountParts || 0;
    }

    set discountParts(value: number) {
        this._discountParts = Number(value.toFixed(2));
        this._valueDiscountParts = Utilities.calcPercentage(value, this.baseOfPartsDiscount);
    }

    get discountParts() {
        return this._discountParts || 0;
    }

    set valueDiscountLabors(value: number) {
        this._valueDiscountLabors = Number(value.toFixed(2));
        this._discountLabors = Utilities.getPercentage(this._valueDiscountLabors, this.baseOfLaborsDiscount, 10);
    }

    get valueDiscountLabors() {
        return this._valueDiscountLabors || 0;
    }

    set discountLabors(value: number) {
        this._discountLabors = Number(value.toFixed(2));
        this._valueDiscountLabors = Utilities.calcPercentage(value, this.baseOfLaborsDiscount);
    }

    get discountLabors() {
        return this._discountLabors || 0;
    }

    set valueDiscountOs(value: number) {
        this._valueDiscountOs = Number(value.toFixed(2));
        this._generalDiscount = Utilities.getPercentage(this._valueDiscountOs, this.baseOfGeneralDiscount);
    }

    get valueDiscountOs() {
        return this._valueDiscountOs || 0;
    }

    get generalDiscount() {
        return this._generalDiscount
    }
    set generalDiscount(value: number) {
        this._generalDiscount = value;
        this.valueDiscountOs = Utilities.calcPercentage(value, this.baseOfGeneralDiscount);
    }

    /** The not-rejected parts */
    get availableParts() {
        return this.parts.filter(p => p.available != partStates.rejected.id);
    }

    /** the not-rejected partsTMP */
    get availablePartsTMP() {
        return this.partsTMP.filter(ptmp => ptmp.status != DESCRIPTIVE_ITEM_STATUS.INACTIVE);
    }

    /** The not rejected labors */
    get availableLabors() {
        return this.labors.filter(l => l.available != laborStates.rejected.id);
    }

    /** The not rejected laborsTMP */
    get availableLaborsTMP() {
        return this.laborsTMP.filter(ltmp => ltmp.status != DESCRIPTIVE_ITEM_STATUS.INACTIVE);
    }

    /** The sum of gross value of parts and partsTMP */
    get partsGrossValue() {
        const partTMPSum = this.availablePartsTMP.reduce((acc, partTMP) => acc + (partTMP.amount * partTMP.saleValue), 0);
        return this.availableParts.reduce((acc, part) => acc + (part.amount * part.saleValue), partTMPSum);
    }

    /** The sum of gross value of labors and laborsTMP */
    get laborsGrossValue() {
        const laborsTMPSum = this.availableLaborsTMP.reduce((acc, laborTMP) => acc + (laborTMP.amount * laborTMP.saleValue), 0);
        return this.availableLabors.reduce((acc, labor) => acc + (labor.amount * labor.saleValue), laborsTMPSum);
    }

    /** The sum of additionalDiscount of not-rejected parts */
    get sumPartsAdditionalDiscount() {
        return this.availableParts.reduce((acc, part) => acc + (part.discountValue || 0), 0);
    }

    /** The sum of additionalDiscount of not-rejected labors */
    get sumLaborsAdditionalDiscount() {
        return this.availableLabors.reduce((acc, labor) => acc + (labor.discountValue || 0), 0);
    }

    /** Sum of collectedValue of thirdPartyServices */
    get outsourcedValue(): number {
        return this.thirdPartyServices.reduce((acc, out) => acc + out.collectedValue, 0)
    }

    /** Sum of paid of thirdPartyServices */
    get outsourcedPaidValue(): number {
        return this.thirdPartyServices.reduce((acc, out) => acc + out.paymentValue, 0)
    }

    get grossValue(): number {
        return this.partsGrossValue + this.laborsGrossValue + this.outsourcedValue
    }

    set partsNetValue(value: number) {
        this.valueDiscountParts = this.partsGrossValue - this.sumPartsAdditionalDiscount - value;
    }
    get partsNetValue() {
        return this.partsGrossValue - this.sumPartsAdditionalDiscount - this.valueDiscountParts;
    }

    set laborsNetValue(value: number) {
        this.valueDiscountLabors = this.laborsGrossValue - this.sumLaborsAdditionalDiscount - value;
    }
    get laborsNetValue() {
        return this.laborsGrossValue - this.sumLaborsAdditionalDiscount - this.valueDiscountLabors;
    }

    /** The value used to calculate the general discount of OS */
    get baseOfGeneralDiscount(): number {
        return this.partsNetValue + this.laborsNetValue + this.outsourcedValue;
    }

    /** The value used to calculate the discount geral of parts */
    get baseOfPartsDiscount(): number {
        return this.partsGrossValue - this.sumPartsAdditionalDiscount;
    }

    /** The value used to calculate the discount geral of labors */
    get baseOfLaborsDiscount(): number {
        return this.laborsGrossValue - this.sumLaborsAdditionalDiscount;
    }

    get isBudgetOrRejected(): boolean {
        return ["budget", "rejected"].includes(this.stateGroup);
    }

    get isBudget(): boolean {
        return this.stateGroup == "budget";
    }

    get isOS(): boolean {
        return ["opened", "closed", "ended"].includes(this.stateGroup);
    }

    /** budgetCode or codeSystem, according state */
    get relativeCode(): number {
        return this.isBudgetOrRejected ? this.budgetCode : this.codeSystem;
    }

    get isRejected(): boolean {
        return this.stateGroup === "rejected";
    }

    get isOpened() {
        return this.stateGroup === "opened";
    }

    get isClosed() {
        return this.stateGroup === "closed";
    }

    get isEnded() {
        return this.stateGroup === "ended";
    }

    get isClosedOrEnded(): boolean {
        return ["closed", "ended"].includes(this.stateGroup);
    }

    get isClosedOrEndedOrRejected(): boolean {
        return ["closed", "ended", "rejected"].includes(this.stateGroup);
    }

    get paymentConditionsSum() {
        return this.paymentConditions.reduce((sum, condition) => sum + condition.value, 0);
    }

    get restantToPay() {
        return Number((this.liquidValue - this.paymentConditionsSum).toFixed(2));
    }

    set liquidValue(value: number) {
        this.valueDiscountOs = this.baseOfGeneralDiscount - value;
    }

    get liquidValue() {
        return this.baseOfGeneralDiscount - this.valueDiscountOs;
    }

    get maxDiscountForParts() {
        return this.partsGrossValue - this.sumPartsAdditionalDiscount;
    }

    get maxDiscountForLabors() {
        return this.laborsGrossValue - this.sumLaborsAdditionalDiscount;
    }

    /** 
     * indicate if the user can modify the OS values 
     */
    get canEdit() {
        return this.isBudget || this.isOpened;
    }


    // recalculatePart(roPart: RoPartFull) {
    //     const grossValue = roPart.amount * roPart.saleValue;
    //     const discountValue = Utilities.calcPercentage(roPart.additionalDiscount, grossValue - roPart.discountProp);
    //     const discountProp = Utilities.calcPercentage(roPart.additionalDiscount, grossValue - discountValue)

    //     roPart.discountValue = Utilities.calcPercentage(roPart.additionalDiscount, grossValue - discountProp);
    //     roPart.discountProp = discountProp;
    //     roPart.discountPropGeral = Utilities.calcPercentage(this.generalDiscount, grossValue - roPart.discountValue - roPart.discountProp);
    //     roPart.liquidValue = grossValue - roPart.discountValue - roPart.discountProp;
    // }

    // recalculateLabor(rolabor: RoLaborFull) {
    //     const grossValue = rolabor.amount * rolabor.saleValue;
    //     rolabor.discountValue = Utilities.calcPercentage(rolabor.additionalDiscount, grossValue);
    //     rolabor.discountProp = Utilities.calcPercentage(this.discountLabors, grossValue - rolabor.discountValue);
    //     rolabor.discountPropGeral = Utilities.calcPercentage(this.generalDiscount, grossValue - rolabor.discountValue - rolabor.discountProp);
    //     rolabor.liquidValue = grossValue - rolabor.discountValue - rolabor.discountProp;
    // }

    static fromForm(formValue: any) {
        const OS = {
            user: formValue.user,
            plate: formValue.plate,
            km: formValue.km,
            problem: formValue.problem,
            prisma: formValue.prisma,
            clientTmp: formValue.clientTmp,
            vehicleTmp: formValue.vehicleTmp
        }

        if (formValue.type) {
            Object.defineProperty(OS, "type", {
                value: formValue.type,
                enumerable: true
            })
        }
        if (formValue.vehicle) {
            Object.defineProperty(OS, "vehicle", {
                value: formValue.vehicle,
                enumerable: true
            })
        }
        if (formValue.client) {
            Object.defineProperty(OS, "client", {
                value: formValue.client,
                enumerable: true
            })
        }
        if (formValue.operation) {
            Object.defineProperty(OS, "operation", {
                value: formValue.operation,
                enumerable: true
            })
        }
        return OS
    }


}