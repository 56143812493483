export const authorizationTypes = {
  rejected: { id: 0, label: 'ELEMENT.AUTHORIZATION_TYPE.REJECTED', name: 'rejected' },
  message: { id: 1, label: 'ELEMENT.AUTHORIZATION_TYPE.MESSAGE', name: 'message' },
  phone: { id: 2, label: 'ELEMENT.AUTHORIZATION_TYPE.PHONE', name: 'phone' },
  personally: { id: 3, label: 'ELEMENT.AUTHORIZATION_TYPE.PERSONALLY', name: 'personally' },
  email: { id: 4, label: 'ELEMENT.AUTHORIZATION_TYPE.EMAIL', name: 'email' },
  web: { id: 5, label: 'ELEMENT.AUTHORIZATION_TYPE.WEB', name: 'web' },
  waiting: { id: 6, label: 'ELEMENT.AUTHORIZATION_TYPE.WAITING', name: 'waiting' },
  additional_authorized: { id: 7, label: 'ELEMENT.AUTHORIZATION_TYPE.ADDITIONAL_AUTHORIZED', name: 'additional_authorized' },
  additional_rejected: { id: 8, label: 'ELEMENT.AUTHORIZATION_TYPE.ADDITIONAL_REJECTED', name: 'additional_rejected' },
}
