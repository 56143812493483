import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, firstValueFrom } from 'rxjs';
import { first, map, switchMap, tap, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { VehicleUtilities } from '../class/vehicle-utilities';
import { Breakdowns } from '../interface/breakdowns';
import { RO_STATES } from '../shared/lists/ro-states';
import { DataService } from './data.service';
import { ApiPaginatedOSQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { PaginatedOS } from '../interface/paginated-os';
import { RoListStateGroup } from '../shared/pipes/ro-state-group.pipe';
import { RoTypeService } from './ro-type.service';
import { CompanyService } from './company.service';
import { RoType } from '../interface/ro-type';
import { Operation } from '../interface/operation';
import { OperationService } from './operation.service';
import { partStates } from '../shared/lists/part-states';
import { laborStates } from '../shared/lists/labor-states';
import { MkgOS } from '../class/mkg-os';


@Injectable({
  providedIn: 'root'
})
export class RoService {
  private _isFromMatrix = false;


  private roList = new Map<string, Map<string, MkgOS>>();
  public timestamps = new Map<string, Map<number, number>>();


  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private _storage: AngularFireStorage,
    private _roTypeService: RoTypeService,
    private _companyService: CompanyService,
    private _operationService: OperationService
    // private _storage: FirebaseStorage
  ) { }

  async get(id: string): Promise<MkgOS> {
    const url = `${environment.mkgoURL}/api/v1/os/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this.http.get(url, header).pipe(
      first(),
      map(resp => new MkgOS(resp)),
      tap((OS) => console.log(OS))
    ));
  }

  /**
   *
   * @param roState The max state to get, for example:
   * if roState is 9, the API will return ROs with currentState between 1 and 9.
   * @param maxTimestamp The last timestamp know
   */
  async getAll(roState: number, maxTimestamp = 0, params?: {
    startDate: string,
    endDate: string
  }): Promise<Map<string, MkgOS>> {
    if (roState == RO_STATES.all) {
      roState = RO_STATES.evaluation;
    } else if (roState == RO_STATES.rejected) {
      roState = RO_STATES.finished;
    }
    const timestamps = this.timestamps.get(this.dataService.company.id)
      || new Map<number, number>();
    this.timestamps.set(this.dataService.company.id, timestamps);

    timestamps.forEach((timestamp, state) => {
      if (state >= roState) {
        maxTimestamp = Math.max(maxTimestamp, timestamp);
      }
    });
    const url = `${environment.mkgoURL}/api/v1/os`;
    const options = {
      headers: { 'Company-CNPJ': this.dataService.company.cnpj },
      params: { state: (roState + 1).toString(), timestamp: maxTimestamp.toString() }
    };

    if (params) {
      options.params = {
        // state: options.params.state,
        startDate: params.startDate,
        endDate: params.endDate
      } as any
    }

    const osArray = await firstValueFrom(this.http.get<{ os: any[] }>(url, options).pipe(
      first(),
      map(resp => resp.os.reverse().map(apiOS => new MkgOS(apiOS)))
    ));

    const osMap = new Map<string, MkgOS>();
    for (const os of osArray) {
      osMap.set(os.id, os)
    }

    return osMap;
    // const roList = this.roList.get(this.dataService.company.id) || new Map<string, Ro>();
    // (response.os as Ro[]).reverse();
    // (response.os as Ro[]).forEach(ro => {
    //   RoFullUtilities.complyApp(ro);
    //   maxTimestamp = Math.max(maxTimestamp, new Date(ro.lastUpdate || 0).getTime());
    //   roList.set(ro.id, ro);
    // });
    // this.roList.set(this.dataService.company.id, roList);
    // timestamps.set(roState, maxTimestamp);
    // return roList;
  }

  getPaginated(state: RoListStateGroup, queryParams: ApiPaginatedOSQueryParams = { all: 1 }, cnpj?: string): Observable<ApiPaginatedResponse<PaginatedOS>> {
    const url = `${environment.mkgoURL}/api/v1/os/mobile`;
    let params;
    // if (queryParams.search) {
    // params = queryParams;
    // } else {
    const states = this._getStateCodes(state);
    params = PaginationService.getParams({ ...queryParams, state: states });
    // }
    const _cnpj = cnpj !== undefined ? cnpj : this._isFromMatrix;
    return this.dataService.httpOptions(_cnpj, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedResponse<PaginatedOS>>(url, options)),
      first(),
      map(resp => {
        if (Array.isArray(resp)) {
          // empty arrays is returned when no docs are found
          return {
            docs: resp,
            hasNextPage: false,
            hasPrevPage: false,
            limit: queryParams.limit,
            nextPage: false,
            page: queryParams.page,
            prevPage: false,
            totalDocs: resp.length,
            pagingCounter: undefined,
            totalPages: 0
          } as ApiPaginatedResponse<PaginatedOS>
        } else {
          return resp
        }
      }),
      map(resp => { // divide some values by 100
        resp.docs.map(os => {

          os.cnpj = cnpj || (this.dataService.company.cnpj);

          if (state === 'rejected') {
            os.currentState = RO_STATES.rejected;
          }
          os.liquidValue /= 100;

          os.parts.map(ospart => {
            ospart.purchaseValue /= 100;
            ospart.purchaseAverage /= 100;
            ospart.saleValue /= 100;
            ospart.liquidValue /= 100;
            return ospart;
          });

          os.labors.map(oslabor => {
            oslabor.saleValue /= 100;
            oslabor.liquidValue /= 100;
            return oslabor;
          });

          os.thirdPartyServices.map(outsourced => {
            outsourced.collectedValue /= 100;
            outsourced.paymentValue /= 100;
            return outsourced;
          });

          os.partsNetValue = os.parts.reduce((p1, p2) => p1 + p2.liquidValue, 0);
          os.laborsNetValue = os.labors.reduce((l1, l2) => l1 + l2.liquidValue, 0);
          os.outsourcedPaid = os.thirdPartyServices.reduce((s1, s2) => s1 + s2.paymentValue, 0);
          os.outsourcedCollected = os.thirdPartyServices.reduce((s1, s2) => s1 + s2.collectedValue, 0);
          os.partsGrossValue = os.parts.filter(osPart => osPart.available !== partStates.rejected.id).reduce((sum, roPart) => sum + roPart.amount * roPart.saleValue, 0)
          os.laborsGrossValue = os.labors.filter(osLabor => osLabor.available !== laborStates.rejected.id).reduce((sum, osLabor) => sum + osLabor.amount * osLabor.saleValue, 0)
          os.grossValue = os.partsGrossValue + os.laborsGrossValue + os.outsourcedCollected;
          return os;
        })

        return resp;
      })
    );
  }

  async getOsByPlate(plate: string): Promise<MkgOS[]> {
    const unmasked = VehicleUtilities.removeDiacritics(plate);
    const url = `${environment.mkgoURL}/api/v1/os/vehicle/${unmasked}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this.http.get<{
      os: any[],
      vehicle: { id: string }
    }>(url, options).pipe(
      first(),
      map(resp => resp.os),
      map(osList => osList.map(apiOs => new MkgOS(apiOs)))
    ));
  }

  async getOsByClient(client: string): Promise<MkgOS[]> {
    const url = `${environment.mkgoURL}/api/v1/os/client/${client}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http.get<{ clientOses: any[] }>(url, options)
      .pipe(
        first(),
        map(resp => resp.clientOses),
        map(rolist => rolist.map(ro => new MkgOS(ro)))
      ));
    return response;
  }

  async getPdf(id: string): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/pdf/os/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    return response.url;
  }

  async downloadPdf(key: string) {
    const url = `${environment.mkgoURL}/short-pdf/${key}`;
    const options = {
      headers: { 'Company-CNPJ': this.dataService.company.cnpj }, responseType: 'blob' as 'blob'
    };
    return this.http.get(url, options).pipe(first()).toPromise();
  }

  async add(formValue: any) {
    const os = MkgOS.fromForm(formValue);
    const url = `${environment.mkgoURL}/api/v1/os`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post<{
      id: string,
      code: string
    }>(url, os, header).pipe(first()).toPromise();
  }

  /** state 2(budget) to state 4(authorization) */
  async requestAuthorization(ro: MkgOS) {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}/authorization`;
    let params = {
      discountLabors: ro.discountLabors,
      discountParts: ro.discountParts,
      lastUpdate: new Date().toISOString()
    }
    if (ro.deliveryForecast) {
      params['deliveryForecast'] = new Date(ro.deliveryForecast).toISOString();
    }
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }

  /** return budget to state 2 */
  async returnToBudget(id: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/budget`;
    const params = { lastUpdate: new Date().toISOString() };
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }

  /** from state 4(authorization) to state 5(appointment) */
  async authorize(id: string, authorizationType: number) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/authorization/${authorizationType}`;
    const params = { 'lastUpdate': new Date().toISOString() }
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }

  /** Make the appointment, no changing the ro state **/
  async makeAppointment(id: string, appointment: Date) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/appointment`;
    const params = {
      'appointment': appointment.toISOString(),
      'lastUpdate': new Date().toISOString()
    }
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }

  /** from state 5(appointment) or 6(ready to start) to state 7(started) */
  async open(id: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/start`;
    const params = { 'lastUpdate': new Date().toISOString() };
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }

  /** from state 7(started) to state 8(to receive) */
  async close(id: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/finish`;
    const params = { 'lastUpdate': new Date().toISOString() };
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }

  /** form state 8(to receive) to state 10(finished) */
  async finish(id: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/end`;
    const params = { 'lastUpdate': new Date().toISOString() };
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }


  /** Update fields of OS
   *
   * if you want to change `paymentCondition`, use a [PaymentService](./payment.service.ts) instance
   *
   * if you want to change the `checklist` array, use a [ChecklistService](./checklist.service.ts) instance
  *
   * if you want to change `breakdowns`, use the function {@link saveBreakdows}
   */
  async update(id: string, args: { field: keyof MkgOS, newValue: any }[]) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}`;
    let params = { 'lastUpdate': new Date().toISOString() }
    for (const arg of args) {
      params[arg.field] = arg.newValue;
    }
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.put(url, JSON.stringify(params), header).pipe(first()).toPromise();
  }

  async updateDiscount(ro: MkgOS) {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}/discount`;
    const params = {
      // Update parts values
      partsGrossValue: Number((ro.partsGrossValue * 100).toFixed(2)),
      discountParts: ro.discountParts,
      valueDiscountParts: ro.valueDiscountParts,
      partsNetValue: Number((ro.partsNetValue * 100).toFixed(2)),
      // Update labors values
      laborsGrossValue: Number((ro.laborsGrossValue * 100).toFixed(2)),
      discountLabors: ro.discountLabors,
      valueDiscountLabors: ro.valueDiscountLabors,
      laborsNetValue: Number((ro.laborsNetValue * 100).toFixed(2)),
      // Update OS values
      grossValue: Number((ro.grossValue * 100).toFixed(2)),
      generalDiscount: ro.generalDiscount,
      valueDiscountOs: ro.valueDiscountOs,
      liquidValue: Number((ro.liquidValue * 100).toFixed(2)),
      lastUpdate: new Date().toISOString(),
    }
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.post(url, params, header).pipe(first()).toPromise();
  }

  async createBudgetCode(ro: MkgOS): Promise<number> {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}`;
    let options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const params = new HttpParams().append("budgetCode", "1");
    options['params'] = params;
    const resp = await this.http.post(url, {}, options).pipe(first()).toPromise();

    // update local roList
    if (resp['budgetCode']) {
      const roList = this.roList.get(this.dataService.company.id) || new Map<string, MkgOS>();
      ro.budgetCode = resp['budgetCode']
      roList.set(ro.id, ro)
      this.roList.set(this.dataService.company.id, roList);
      return resp['budgetCode']
    }
    return undefined
  }


  async createCodeSystem(ro: MkgOS): Promise<number> {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}`;
    let options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const params = new HttpParams().append("codeSystem", "1");
    options['params'] = params;
    const resp = await this.http.post(url, {}, options).pipe(first()).toPromise();

    // update local roList
    if (resp['codeSystem']) {
      const roList = this.roList.get(this.dataService.company.id) || new Map<string, MkgOS>();
      ro.codeSystem = resp['codeSystem']
      roList.set(ro.id, ro)
      this.roList.set(this.dataService.company.id, roList);
      return resp['codeSystem']
    }
    return undefined
  }

  // async updateProblem(id: string, problem: string) {
  //   const url = `${environment.mkgoURL}/api/v1/os/${id}/update`
  //   const params = {
  //     'problem': problem,
  //     'lastUpdate': new Date().toISOString()
  //   }
  //   const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
  //   return this.http.put(url, params, header).pipe(first()).toPromise();
  // }

  /** @todo test */
  public async storeDamagesImage(osId: string, blob: Blob): Promise<string> {
    const path = `${this.dataService.company.id}/breakdowns/${osId}`;
    // save the image into firebase
    await this._storage.upload(path, blob);

    // retrieve public url for image
    const url: string = await this._storage.ref(path)
      .getDownloadURL()
      .pipe(first())
      .toPromise();
    return url;
  }

  /** @todo test */
  public async storeAditionalDamagesImage(osId: string, blob: Blob, index: number): Promise<string> {
    const path = `${this.dataService.company.id}/breakdowns_attachments/${osId}/${index}`;
    // save the image into firebase
    await this._storage.upload(path, blob);

    // retrieve public url for image
    const url: string = await this._storage.ref(path)
      .getDownloadURL()
      .pipe(first())
      .toPromise();
    return url;
  }

  public async storeSignatureImage(osId: string, blob: Blob): Promise<string> {
    // prepare path
    const path = `${this.dataService.company.id}/signature/${osId}`;

    // save the image into firebase
    await this._storage.upload(path, blob);

    // retrieve public url for image
    const url: string = await this._storage.ref(path)
      .getDownloadURL()
      .pipe(first())
      .toPromise();

    return url;
  }

  public async getBreakdownsImage(osId: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${osId}/image`;
    const options = {
      headers: { 'Company-CNPJ': this.dataService.company.cnpj },
      responseType: 'blob' as 'blob'
    };
    const resp = await this.http.get(url, options).pipe(timeout(15000), first()).toPromise()
    return resp;
  }

  public async getSignatureImage(osId: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${osId}/signature`;
    const options = {
      headers: { 'Company-CNPJ': this.dataService.company.cnpj },
      responseType: 'blob' as 'blob'
    };
    const resp = await this.http.get(url, options).pipe(timeout(15000), first()).toPromise()
    return resp;
  }


  /** Avoid CORS downloading firebase resources */
  async getBlob(firebaseUrl: string): Promise<Blob> {
    const url = `${environment.mkgoURL}/api/v1/image-downloader`;
    const options = {
      headers: { 'Company-CNPJ': this.dataService.company.cnpj },
      responseType: 'blob' as 'blob'
    };
    const resp = await this.http.post(url, { url: firebaseUrl }, options).pipe(first()).toPromise();
    return resp;
  }

  async saveBreakdows(osId: string, breakdowns: Breakdowns) {
    const url = `${environment.mkgoURL}/api/v1/os/${osId}/breakdowns`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.post(url, breakdowns, options).pipe(first()).toPromise();
    return resp;
  }

  private _getStateCodes(stateGroup: RoListStateGroup) {
    switch (stateGroup) {
      case 'budget':
        return [
          RO_STATES.budget,
          RO_STATES.authorization,
          RO_STATES.appointment,
          RO_STATES.readyToStart
        ];
      case 'opened':
        return [RO_STATES.started];
      case 'integrated_opened':
        return [
          RO_STATES.started,
          RO_STATES.toReceive,
          RO_STATES.evaluation
        ];
      case 'closed':
        return [RO_STATES.toReceive, RO_STATES.evaluation];
      case 'ended':
        return [RO_STATES.finished];
      case 'rejected':
        return [RO_STATES.rejected];
      case "all":
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      default:
        return []
    }
  }

  /** Find cnpj to use on invoices and titles */
  public async getMatrixConfigFor(OS: MkgOS) {
    let companyParts = this.dataService.company;
    let companyLabors = this.dataService.company;
    let operationParts: Operation;
    let operationLabors: Operation;
    let matrixIsFor: "parts" | "labors" | "none" = "none";
    let roType: RoType;

    if (OS.type) {
      roType = await this._roTypeService.get(OS.type);

      if (roType) {
        if (roType.nfeCnpj && roType.nfeCnpj !== companyParts.cnpj) {
          companyParts = await this._companyService.getByCnpj(roType.nfeCnpj, true);
          matrixIsFor = 'parts';
        }

        if (roType.nfseCnpj && roType.nfseCnpj !== companyLabors.cnpj) {
          companyLabors = await this._companyService.getByCnpj(roType.nfseCnpj, true);
          matrixIsFor = 'labors';
        }

        if (roType.operation) {
          let operation: Operation;
          try {
            operation = await this._operationService.getById(roType.operation);
          } catch (error) {
            console.error("Operação do tipo de OS não encontrada", { id: roType.operation })
          }
          if (operation) {
            if (matrixIsFor === "parts") {
              operationParts = operation;
            } else if (matrixIsFor == "labors") {
              operationLabors = operation;
            }
          }
        }

      }
    }


    if (!operationParts) {
      try {
        operationParts = await this._operationService.getById(OS.operationId);
      } catch (error) {
        console.error("Operação da OS não encontrada", { id: OS.operationId })
      }
    }
    if (!operationLabors) {
      try {
        operationLabors = await this._operationService.getById(OS.operationId);
      } catch (error) {
        console.error("Operação da OS não encontrada", { id: OS.operationId })
      }
    }

    return { companyParts, companyLabors, matrixIsFor, roType, operationParts, operationLabors }
  }


  async getStatistics(receiveDateStart: string, receiveDateEnd: string/*params?: {
    receiveDateStart: string,
    receiveDateEnd: string
  }*/)/*: Promise<any>*/ {
    const url = `${environment.mkgoURL}/api/v1/os/statistics`;
    const options = {
      headers: { 'Company-CNPJ': this.dataService.company.cnpj },
      params: {
        receiveDateStart: receiveDateStart, // params.receiveDateStart,
        receiveDateEnd: receiveDateEnd // params.receiveDateEnd
      }
    };

    const resp: any = await this.http.get(url, options).pipe(first()).toPromise();
    return resp;
  }


}
