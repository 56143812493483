export enum DESCRIPTIVE_ITEM_STATUS {
    ACTIVE = 1,
    INACTIVE
}

export interface RoPartTemp {
    _id?: string,
    code: string,
    description: string,
    amount: number,
    saleValue: number,

    status: DESCRIPTIVE_ITEM_STATUS,
    internalObservation?: string;
    observation: string,

    /** local only */
    _netValue?: number
}